const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/1.png",
    text: "Architecture 1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/2.png",
    text: "Architecture 2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/3.png",
    text: "Architecture 3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/4.png",
    text: "Architecture 4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/5.png",
    text: "Architecture 5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/1.png",
    text: "Master Planning 1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/2.png",
    text: "Master Planning 2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/3.png",
    text: "Master Planning 3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/4.png",
    text: "Master Planning 4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/5.png",
    text: "Master Planning 5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/6.png",
    text: "Master Planning 6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/7.png",
    text: "Master Planning 7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/sgtuniversity/masterplanning/8.png",
    text: "Master Planning 8",
  },
];

export default data;
